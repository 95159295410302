<template>
  <v-card class="rounded-0" elevation="0" width="100%">
    <v-list class="pa-0" v-for="(item, index) in list" :key="index">
      <v-list-group v-if="item.list.length > 0" class="list" color="#999999">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title
              class="d-flex list_text"
              v-text="item.title"
            ></v-list-item-title>
          </v-list-item-content>
          <div class="border_bottom"></div>
        </template>

        <diagnosis_start_list :list="item.list" :depth="depth + 1" />
      </v-list-group>
      <v-list-item v-else class="list" :class="depth == 0 ? '' : 'dark'">
        <div class="list_icon">
          <img height="100%" :src="item.icon_img" />
        </div>
        <v-list-item-content>
          <v-list-item-title
            class="d-flex list_text"
            v-text="item.title"
          ></v-list-item-title>
        </v-list-item-content>
        <div class="border_bottom"></div>
        <v-btn rounded class="list_button" @click="go(item)">
          {{ $ml.get("d_s_list_btn") }}
        </v-btn>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: "diagnosis_start_list",
  props: ["list", "depth"],
  data() {
    return {};
  },
  methods: {
    go: function(val) {
      this.$store.state.diagnosis_data = val;
      this.$router.push("/d_info");
    }
  }
};
</script>

<style lang="scss">
@import "src/assets/css/common";

.dark.list {
  background-color: $bg-color-d_s-list_bg_dark;
}

.border_bottom {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  border-bottom: solid 1px #767676;
}

.list_icon {
  height: 35px;
  width: 105px;
}

.list_text {
  height: 50px;
  align-items: center;
  font: normal normal normal 28px/32px Noto !important;
  letter-spacing: 0px;
  color: $ft-color-title;
}

.dark .list_text {
  color: $ft-color-title-white;
  padding: 0px 20px;
}

.list_button {
  height: 50px !important;
  width: 100px;
  background-color: $bg-color-d_s-list_btn !important;
  color: $ft-color-d_s-list_btn !important;
  font: normal normal bold 24px/30px Noto !important;
}

.dark .list_button {
  background-color: $bg-color-d_s-list_dark_btn !important;
  color: $ft-color-d_s-list_dark_btn !important;
}

.sm {
  .list_icon {
    height: 6vw;
    width: 18vw;
  }

  .list_text {
    height: calc(16vw - 24px);
    font: normal normal normal 4vw/5vw Noto !important;
  }

  .list_button {
    min-width: 0px !important;
    height: 8vw !important;
    width: 14vw;
    font: normal normal normal 4vw/5vw Noto !important;
  }
}
</style>
<style lang="scss">
@import "src/assets/css/common";
/* vuetify custom */
.v-list-item__icon .v-icon {
  color: $ft-color-d_s-list_arrow !important;
  width: 100px;
  font-size: 60px !important;
}

.sm .v-list-item__icon .v-icon {
  width: 8vw !important;
  font-size: 8vw !important;
}
</style>
