<template>
  <v-main class="fullwidth fullheight bg_card" :class="$mq">
    <!-- PC 웹 & 모바일 -->
    <v-card
      flat
      tile
      class="mx-auto"
      min-width="320"
      :max-width="$store.state.web_max_width"
      color="transparent"
    >
      <v-row justify="center" class="title_root">
        <v-col cols="12" class="title_root_text font_white">
          {{ $ml.get("main_button_02") }}
        </v-col>
        <div class="title_root_text_line font_white"></div>
        <v-btn
          elevation="0"
          class="title_root_back"
          icon
          v-on:click="$router.go(-1)"
        >
        </v-btn>
      </v-row>

      <v-row justify="center" class="main_root">
        <v-card
          :class="$mq == 'sm' ? 'rounded-xl' : ''"
          color="transparent"
          width="100%"
        >
          <v-row justify="center" class="main_title_1">
            <v-btn
              elevation="0"
              class="main_root_back"
              icon
              v-on:click="$router.go(-1)"
            >
            </v-btn>
            <v-col cols="12" class="main_title_1_text">
              {{ title }}
            </v-col>
          </v-row>
          <v-row v-if="$mq == 'sm'" justify="center" class="main_title_1 sub">
            <v-col cols="12" class="main_title_1_text">
              {{ $ml.get("d_s_title_m") }}
            </v-col>
          </v-row>

          <DiagnosisStartList :list="api_result.list" :depth="0" />

          <v-row v-if="$mq == 'sm'" justify="center" class="main_title_1 sub">
            <v-col cols="12" class="main_title_1_text"> </v-col>
          </v-row>
        </v-card>
      </v-row>
    </v-card>
  </v-main>
</template>

<script>
import DiagnosisStartList from "../components/DiagnosisStartList.vue";

export default {
  components: {
    DiagnosisStartList
  },
  data: function() {
    return {
      api_result: {}
    };
  },
  computed: {
    title() {
      return this.$mq == "sm"
        ? this.$ml.get("main_button_02")
        : this.$ml.get("d_s_title");
    }
  },
  methods: {},
  created() {
    //api_content_list
    var self = this;
    var api = "api_survey_list.php";
    var param = {};

    self.$store.dispatch("apiCall", { api, param }).then(() => {
      var data = self.$store.state.api_result;

      if (data == false || data == "err") {
        // error
        alert(
          "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
        );
      } else {
        if (data.ret_code == "S") {
          self.api_result = data;
        } else {
          alert(
            "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
          );
        }
      }
    });
  },
  mounted: function() {},
  watch: {}
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/common";

.bg_card {
  background-image: url("../assets/bg_self.jpg");
}

.sm.bg_card {
  background-image: url("../assets/bg_self_m.jpg");
}

/* mobile */
</style>
