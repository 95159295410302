<template>
  <v-main class="bg_color" :class="$mq">
    <!-- PC 웹 & 모바일 -->
    <v-card
      flat
      tile
      class="mx-auto"
      min-width="320"
      :max-width="$store.state.web_max_width"
      color="transparent"
    >
      <v-img class="mt-16 mx-auto" width="600" src="../assets/404_img.png" />
      <div style="font-size: 30px; font-weight: bold; margin-bottom: 30px;">
        페이지를 찾을 수 없습니다.
      </div>
      <div style="font-size: 26px; color: #999999; margin-bottom: 40px;">
        페이지가 존재하지 않거나, 사용할 수 없는 페이지입니다.
        <br />
        입력하신 주소가 정확한지 다시 한 번 확인해주세요.
      </div>
      <v-btn elevation="0" width="580" height="120" @click="$router.push('/')">
        <v-img width="580" height="120" contain src="../assets/404_btn.png" />
      </v-btn>
    </v-card>
  </v-main>
</template>

<script>
export default {
  data: function() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
  watch: {}
};
</script>
