<template>
  <v-main class="fullwidth fullheight bg_card" :class="$mq">
    <!-- PC 웹 & 모바일 -->
    <v-card
      flat
      tile
      class="mx-auto"
      min-width="320"
      :max-width="$store.state.web_max_width"
      color="transparent"
    >
      <v-row justify="center" class="title_root">
        <v-col cols="12" class="title_root_text">
          {{ $ml.get("main_button_04") }}
        </v-col>
        <div class="title_root_text_line"></div>
        <v-btn
          elevation="0"
          class="title_root_back"
          icon
          v-on:click="$router.push('/')"
        >
        </v-btn>
      </v-row>

      <v-row justify="center" class="main_root">
        <v-card
          :class="$mq == 'sm' ? 'rounded-xl' : 'rounded-b-xl'"
          :color="$mq == 'sm' ? '#FFFFFFBB' : '#FFFFFF'"
          width="100%"
        >
          <v-row v-if="$mq == 'sm'" justify="center" class="main_title_1">
            <v-btn
              elevation="0"
              class="main_root_back"
              icon
              v-on:click="$router.go(-1)"
            >
            </v-btn>
            <v-col cols="12" class="main_title_1_text">
              {{ $ml.get("main_button_04") }}
            </v-col>
          </v-row>
          <!-- 컨텐츠 탑 버튼 영역 :: start -->
          <v-row class="ma-0 pa-3 content_tap_btn_bg">
            <v-col
              v-for="(el, index) in category_name_List"
              :key="index"
              class="pa-0 d-flex flex-column align-center justify-center content_tap_btn"
              :class="num === index ? 'on_0' : ''"
              v-on:click="menu(index)"
            >
              <v-img
                v-if="$mq == 'sm'"
                width="9vw"
                height="9vw"
                :src="new_category_icon_list[`${index}`]"
                contain
              >
              </v-img>

              <!-- <v-row class="d-flex align-center">
                {{ category_name_List[`${index}`] }}
              </v-row> -->
              <div class="category_name">
                {{ category_name_List[`${index}`] }}
              </div>
            </v-col>
          </v-row>
          <!-- 컨텐츠 탑 버튼 영역 :: end -->

          <v-row class="ma-0" v-if="category_list_type[num] === 'list'">
            <v-img class="" src="@/assets/Artboard_16.jpg" contain> </v-img>
          </v-row>

          <div v-if="category_list_type[num] === 'thumbnail'">
            <ContentsThumb :healing_list="child_data" />
          </div>
          <div v-else-if="category_list_type[num] === 'list'">
            <ContentsList :data="child_data" />
          </div>
          <div v-else-if="category_list_type[num] === 'thanks'">
            <ThanksDiary :title="title_sub" />
          </div>
          <div v-else>
            <v-progress-circular
              :size="100"
              :width="7"
              color="#333333"
              indeterminate
              style="margin-bottom:50px;"
            ></v-progress-circular>
          </div>
        </v-card>
      </v-row>
    </v-card>
  </v-main>
</template>

<script>
import ContentsList from "../components/ContentsList.vue";
import ContentsThumb from "../components/ContentsThumb.vue";
import ThanksDiary from "../components/ThanksDiary.vue";
// let vm = null;

export default {
  components: {
    ContentsList,
    ContentsThumb,
    ThanksDiary
  },

  created() {
    // //api_content_list
    // console.log("test");
    // // console.log(this.category_icon_list);
    // console.log(this.category_icon_list.length);
    // for(let i = 0; i < this.category_icon_list.length; i++) {
    //   console.log(this.$route.query.type)
    //   if(this.$route.query.type === i) {
    //     console.log(this.new_category_icon_list);
    //     this.new_category_icon_list.push(this.category_icon_on_list[i]);
    //   }  else {
    //     this.new_category_icon_list.push(this.category_icon_off_list[i]);
    //   }
    // }

    var self = this;
    var api = "content.php";
    var param = {};

    self.$store.dispatch("apiCall", { api, param }).then(() => {
      var data = self.$store.state.api_result;
      // console.log(data);
      if (data == false || data == "err") {
        // error
        alert(
          "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
        );
      } else {
        try {
          if (data.ret_code == "S") {
            for (let i = 0; i < data.category_list.length; i++) {
              this.category_name_List.push(
                data[`${data.category_list[i].category_title}_category_name`]
              );

              this.category_name_sub_List.push(
                data[`${data.category_list[i].category_title}_detail`]
              );

              this.category_healing_list.push(
                data[`${data.category_list[i].category_title}_list`]
              );

              this.category_icon_list.push(
                data[`${data.category_list[i].category_title}_icon`]
              );

              this.category_list_type.push(
                data[`${data.category_list[i].category_title}_list_type`]
              );

              this.category_icon_on_list.push(
                data[`${data.category_list[i].category_title}_icon_img_on`]
              );
              this.category_icon_off_list.push(
                data[`${data.category_list[i].category_title}_icon_img_off`]
              );
            }

            var type = this.$route.query.type;

            //! 변경
            self.menu(type);
            // self.menu(1);
          } else {
            // alert(
            //   "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
            // );
          }
        } catch (e) {
          // alert("정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요.");
        }
      }
    });
  },
  data: function() {
    return {
      num: 0,
      title: "",
      title_sub: "",
      category_healing_list: [],
      category_name_List: [],
      category_icon_list: [],
      category_name_sub_List: [],
      category_list_type: [],
      category_icon_on_list: [],
      category_icon_off_list: [],
      child_data: [],
      clickCheck: false,
      new_category_icon_list: []
    };
  },

  computed: {},
  methods: {
    clickValidation() {
      if (!this.clickCheck) {
        this.clickCheck = !this.clickCheck;
      } else {
        this.clickCheck = !this.clickCheck;
      }
    },
    menu: function(val) {
      this.num = parseInt(val, 10);
      if (!Number.isInteger(this.num)) {
        this.num = 0;
      }

      this.title_sub = this.category_name_sub_List[this.num];
      this.child_data = this.category_healing_list[this.num];

      this.new_category_icon_list = [];
      for (let i = 0; i < this.category_icon_on_list.length; i++) {
        if (this.num === i) {
          this.new_category_icon_list.push(this.category_icon_on_list[i]);
        } else {
          this.new_category_icon_list.push(this.category_icon_off_list[i]);
        }
      }

      this.$router.replace({ query: { type: this.num } }).catch(() => {}); //router
    }
  },
  watch: {}
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/common";
.bg_card {
  background-image: url("../assets/bg_healing.jpg");
}

.content_tap_btn_bg {
  background-color: $bg-color-main-title;
}

.content_tap_btn {
  height: 80px;
  text-align: center;
  font: normal normal bold 28px/30px Noto;
  letter-spacing: 0px;
  color: $ft-color-contents-tap;
  cursor: pointer;
}

.content_tap_btn.on_0 {
  color: $ft-color-contents-tap-on;
}

.thanks_healing_color {
  background-color: $bg-color-contents-tanksdiary;
}

.thanks_contents_info {
  background-color: $bg-color-contents-tanksdiary;
  color: $ft-color-contents-tanksdiary;
  font-weight: bold;
}

/* mobile */
.sm.bg_card {
  background-image: url("../assets/bg_healing_m.jpg");

  .content_tap_btn_bg {
    background-color: $bg-color-contents-tap-m;
  }

  .content_tap_btn {
    height: 19vw;
    font: normal normal bold 3vw/5vw Noto;
    // font: normal normal bold 10px/10px Noto;
    color: $ft-color-contents-tap-m;
  }

  .content_tap_btn.on_0 {
    color: $ft-color-contents-tap-m-on;
  }

  .category_name {
    width: 13vw;
  }
}
</style>
