<template>
  <v-main class="fullwidth fullheight bg_card" :class="$mq">
    <!-- PC 웹 & 모바일 -->
    <v-card
      flat
      tile
      class="mx-auto"
      min-width="320"
      :max-width="$store.state.web_max_width"
      color="transparent"
    >
      <v-row justify="center" class="title_root">
        <v-col cols="12" class="title_root_text">
          {{ $ml.get("main_button_03") }}
        </v-col>
        <div class="title_root_text_line"></div>
        <v-btn
          elevation="0"
          class="title_root_back"
          icon
          v-on:click="$router.go(-1)"
        >
        </v-btn>
      </v-row>

      <v-row justify="center" class="main_root">
        <!-- write -->
        <v-card
          v-if="!success"
          :class="$mq == 'sm' ? 'rounded-xl' : 'rounded-b-xl'"
          color="transparent"
          width="100%"
        >
          <v-row justify="center" class="main_title_1">
            <v-btn
              elevation="0"
              class="main_root_back"
              icon
              v-on:click="$router.go(-1)"
            >
            </v-btn>
            <v-col cols="12" class="main_title_1_text">
              {{ title }}
            </v-col>
          </v-row>

          <v-textarea
            class="rounded-0 counsel_write_textarea"
            :background-color="$mq == 'sm' ? '#FFFFFFBB' : '#FFFFFF'"
            :value="input_counsel"
            @change="v => (input_counsel = v)"
            no-resize
            filled
            hide-details
            solo
            :height="$mq == 'sm' ? '80vw' : 280"
            :placeholder="$ml.get('counsel_write_input_ph')"
          >
          </v-textarea>

          <v-card
            elevation="0"
            class="d-flex flex-column align-center justify-center rounded-0 pa-3 main_title_1_text gray counsel_write_input_max"
          >
            <div class="fullwidth d-flex justify-space-between">
              <v-btn
                class="rounded-xl list_button"
                :class="input_counsel_type == 0 ? 'on' : ''"
                @click="input_counsel_type = 0"
              >
                {{ $ml.get("counsel_write_type_1") }}
              </v-btn>
              <v-btn
                class="rounded-xl list_button"
                :class="input_counsel_type == 1 ? 'on' : ''"
                @click="input_counsel_type = 1"
              >
                {{ $ml.get("counsel_write_type_2") }}
              </v-btn>
              <v-btn
                class="rounded-xl list_button"
                :class="input_counsel_type == 2 ? 'on' : ''"
                @click="input_counsel_type = 2"
              >
                {{ $ml.get("counsel_write_type_3") }}
              </v-btn>
            </div>
          </v-card>

          <v-card
            v-if="$store.getters.getId == ''"
            elevation="0"
            class="d-flex flex-column align-center justify-center rounded-0 pa-3 pt-0 main_title_1_text gray counsel_write_input_max"
          >
            <v-form
              ref="form"
              class="fullwidth d-flex flex-wrap justify-space-between"
            >
              <v-text-field
                class="counsel_write_input"
                :class="$mq == 'sm' ? 'rounded-lg' : 'rounded-xl'"
                solo
                dense
                :height="$mq == 'sm' ? '10vw' : 58"
                :placeholder="$ml.get('counsel_login_tel_label')"
                persistent-hint
                :rules="tel_rules"
                :value="input_tel"
                @change="v => (input_tel = v)"
                required
              ></v-text-field>

              <v-text-field
                class="counsel_write_input"
                :class="$mq == 'sm' ? 'rounded-lg' : 'rounded-xl'"
                solo
                dense
                :height="$mq == 'sm' ? '10vw' : 58"
                :append-icon="show_pw ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show_pw ? 'text' : 'password'"
                @click:append="show_pw = !show_pw"
                :placeholder="$ml.get('counsel_login_pw_label')"
                persistent-hint
                :rules="pw_rules"
                :value="input_pw"
                @change="v => (input_pw = v)"
                required
              ></v-text-field>

              <v-text-field
                class="counsel_write_input"
                :class="$mq == 'sm' ? 'rounded-lg' : 'rounded-xl'"
                solo
                dense
                :height="$mq == 'sm' ? '10vw' : 58"
                :append-icon="show_pw_check ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show_pw_check ? 'text' : 'password'"
                @click:append="show_pw_check = !show_pw_check"
                :placeholder="$ml.get('counsel_login_pw_check_label')"
                persistent-hint
                :rules="pw_check_rules"
                :value="input_pw_check"
                @change="v => (input_pw_check = v)"
                required
              ></v-text-field>
            </v-form>
          </v-card>
          <v-btn class="rounded-0 rounded-b-xl button_3" v-on:click="write()">
            {{ $ml.get("counsel_write_btn") }}
          </v-btn>
        </v-card>
        <!-- success -->
        <v-card
          v-else
          :class="$mq == 'sm' ? 'rounded-xl' : 'rounded-b-xl'"
          color="transparent"
          width="100%"
        >
          <v-row justify="center" class="main_title_1">
            <v-btn
              elevation="0"
              class="main_root_back"
              icon
              v-on:click="$router.go(-1)"
            >
            </v-btn>
            <v-col cols="12" class="main_title_1_text">
              {{ title_2 }}
            </v-col>
          </v-row>
          <v-row v-if="$mq == 'sm'" justify="center" class="main_title_1 sub">
            <v-col cols="12" class="main_title_1_text">
              {{ $ml.get("counsel_list_success_title") }}
            </v-col>
          </v-row>

          <v-card
            elevation="0"
            :min-height="$mq == 'sm' ? '35vw' : 250"
            class="d-flex align-center justify-center rounded-0 info_text_0"
            :color="$mq == 'sm' ? '#FFFFFFBB' : '#FFFFFF'"
            v-html="$ml.get('counsel_list_success_title_2')"
          >
          </v-card>
          <v-card
            elevation="0"
            :min-height="$mq == 'sm' ? '25vw' : 150"
            class="d-flex justify-center rounded-0 info_text_1"
            :color="$mq == 'sm' ? '#FFFFFFBB' : '#FFFFFF'"
            v-html="$ml.get('counsel_list_success_title_3')"
          >
          </v-card>
          <v-btn
            class="rounded-0 rounded-b-xl button_3"
            v-on:click="$router.push('/')"
          >
            {{ $ml.get("go_home_btn") }}
          </v-btn>
        </v-card>
      </v-row>
    </v-card>
  </v-main>
</template>

<script>
import { regexCheck } from "../common";

export default {
  data: function() {
    return {
      success: false,
      input_counsel: "",
      input_counsel_type: 0,
      input_tel: "",
      show_pw: false,
      input_pw: "",
      show_pw_check: false,
      input_pw_check: "",
      tel_rules: [
        value => {
          return (
            regexCheck(1, value) || this.$ml.get("counsel_login_tel_rules_1")
          );
        },
        value =>
          (value || "").length == 11 ||
          this.$ml.get("counsel_login_tel_rules_2")
      ],
      pw_rules: [
        value => {
          return (
            regexCheck(1, value) || this.$ml.get("counsel_login_pw_rules_1")
          );
        },
        v => v.length == 4 || this.$ml.get("counsel_login_pw_rules_2")
      ],
      pw_check_rules: [
        value => {
          return (
            value == this.input_pw ||
            this.$ml.get("counsel_login_pw_check_rules_1")
          );
        }
      ]
    };
  },
  computed: {
    title() {
      return this.$mq == "sm"
        ? this.$ml.get("main_button_03")
        : this.$ml.get("counsel_write_title");
    },
    title_2() {
      return this.$mq == "sm"
        ? this.$ml.get("main_button_03")
        : this.$ml.get("counsel_list_success_title");
    }
  },
  methods: {
    write: function() {
      console.log(this.$refs.form)
      var self = this;
      if (this.input_counsel.trim().length <= 5) {
        const title = this.$ml.get("counsel_write_alert_title");
        const info = this.$ml.get("counsel_write_alert_error_1");
        this.$store.commit("alert_show", { title, info });
      } else if (this.$refs.form != null && !this.$refs.form.validate()) {
        return;
      } else {
        //loading
        self.$ionic.loadingController
          .create({
            message: self.$ml.get("loading_text")
          })
          .then(l => {
            l.present();
            self.$store.commit("loading_update", l);
          });

        //api_counseling_insert
        var api = "api_counseling_insert.php";
        var param = {
          content: encodeURI(this.input_counsel),
          tel: encodeURI(this.input_tel),
          pw: encodeURI(this.input_pw),
          counseling_gubun: encodeURI(this.input_counsel_type)
        };

        self.$store.dispatch("apiCall", { api, param }).then(() => {
          //loading hide
          setTimeout(function() {
            try {
              self.$store.state.loading.dismiss();
            } catch (e) {
              //empty
            }
          }, 100);

          var data = self.$store.state.api_result;

          if (data == false || data == "err") {
            // error
            alert(
              "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
            );
          } else {
            if (data.ret_code == "S") {
              const p_cookie = data.login_token;
              self.$store.dispatch("login", { p_cookie });
              self.success = true;
            } else {
              const title = self.$ml.get("counsel_write_alert_title");
              const info = data.ret_msg;
              self.$store.commit("alert_show", { title, info });
            }
          }
        });
      }
    }
  },
  created() {},
  watch: {}
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/common";

.bg_card {
  background-image: url("../assets/bg_counsel.jpg");
}

.main_title_1_text.gray {
  background-color: $bg-color-counsel_write-gray;
}

.counsel_write_textarea {
  font: normal normal normal 18px/24px Noto !important;
  letter-spacing: 0px;
}

.counsel_write_input_max {
  padding-left: 140px !important;
  padding-right: 140px !important;
}

.counsel_write_input {
  width: 100%;
  font: normal normal normal 18px/24px Noto !important;
  letter-spacing: 0px;
  margin-bottom: 8px;
}

.counsel_write_input:nth-child(n + 2) {
  max-width: 48%;
}

.list_button {
  height: 58px !important;
  width: 200px;
  background-color: $bg-color-d_s-list_btn !important;
  color: $ft-color-d_s-list_btn !important;
  font: normal normal bold 18px/24px Noto !important;
}

.list_button.on {
  background-color: $bg-color-d_s-list_dark_btn !important;
  color: $ft-color-d_s-list_dark_btn !important;
}

.sm.bg_card {
  background-image: url("../assets/bg_counsel_m.jpg");

  .counsel_write_textarea {
    font: normal normal normal 4.6vw/7vw Noto !important;
  }

  .list_button {
    min-width: 0px !important;
    height: 10vw !important;
    width: 26vw;
    font: normal normal normal 3.2vw/5vw Noto !important;
  }

  .main_title_1_text.gray {
    background-color: $bg-color-counsel_write-gray + BB;
  }

  .counsel_write_input_max {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .counsel_write_input {
    font: normal normal normal 3.2vw/5vw Noto !important;
    margin-bottom: 0px;
  }
}
</style>
