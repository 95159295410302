<template>
  <v-main class="fullwidth fullheight bg_card" :class="$mq">
    <!-- PC 웹 & 모바일 -->
    <v-card
      flat
      tile
      class="mx-auto"
      min-width="320"
      :max-width="$store.state.web_max_width"
      color="transparent"
    >
      <v-row justify="center" class="title_root">
        <v-col cols="12" class="title_root_text">
          {{ $ml.get("main_button_03") }}
        </v-col>
        <div class="title_root_text_line"></div>
        <v-btn
          elevation="0"
          class="title_root_back"
          icon
          v-on:click="$router.go(-1)"
        >
        </v-btn>
      </v-row>

      <v-row justify="center" class="main_root">
        <v-card
          :class="$mq == 'sm' ? 'rounded-xl' : 'rounded-b-xl'"
          color="transparent"
          width="100%"
        >
          <v-row v-if="$mq == 'sm'" justify="center" class="main_title_1">
            <v-btn
              elevation="0"
              class="main_root_back"
              icon
              v-on:click="$router.go(-1)"
            >
            </v-btn>
            <v-col cols="12" class="main_title_1_text">
              {{ $ml.get("main_button_03") }}
            </v-col>
          </v-row>
          <v-card
            elevation="0"
            class="rounded-0"
            color="transparent"
            width="100%"
          >
            <v-row no-gutters class="justify-center">
              <v-row
                no-gutters
                class="justify-center pa-0 table_title table_bg"
              >
                <div class="left">
                  <span>{{ $ml.get("counsel_list_table_title_1") }}</span>
                </div>
                <div class="bar_right_line">
                  |
                </div>
                <v-col
                  ><span>{{
                    $ml.get("counsel_list_table_title_2")
                  }}</span></v-col
                >
                <div class="bar_right_line">
                  |
                </div>
                <div class="right">
                  <span>{{ $ml.get("counsel_list_table_title_3") }}</span>
                </div>
              </v-row>
              <div class="bar_bottom_line fullwidth"></div>
              <v-row class="justify-center ma-0 table_text">
                <div class="left">
                  <span>{{ result.idx }}</span>
                </div>
                <v-col class="center"
                  ><span>
                    {{ result.content }}
                  </span>
                </v-col>
                <div class="right">
                  <span> {{ result.reg_date }} </span>
                </div>
              </v-row>
              <div class="bar_bottom_line fullwidth"></div>
              <v-row no-gutters class="justify-center table_title table_bg">
                <div class="left">
                  <span>{{ $ml.get("counsel_list_table_title_1") }}</span>
                </div>
                <div class="bar_right_line">
                  |
                </div>
                <v-col
                  ><span>{{
                    $ml.get("counsel_list_table_title_4")
                  }}</span></v-col
                >
                <div class="bar_right_line">
                  |
                </div>
                <div class="right">
                  <span>{{ $ml.get("counsel_list_table_title_5") }}</span>
                </div>
              </v-row>
              <div class="bar_bottom_line fullwidth"></div>
              <v-row class="justify-center ma-0 table_text">
                <div class="left">
                  <span>{{ result.idx }}</span>
                </div>
                <v-col class="center"
                  ><span>
                    {{ result.comment_content }}
                  </span>
                </v-col>
                <div class="right">
                  <span>
                    {{ result.comment_reg_date }}
                  </span>
                </div>
              </v-row>
              <div class="bar_bottom_line fullwidth"></div>
              <v-card-actions class="justify-center paging_bg">
                <v-card-actions class="justify-center pa-0">
                  <v-btn icon class="size_arrow" v-on:click="first_prevPage()">
                    <v-icon class="size_arrow color_arrow">
                      mdi-chevron-double-left
                    </v-icon>
                  </v-btn>
                </v-card-actions>
                <v-card-actions class="justify-center pa-0">
                  <v-btn icon class="size_arrow" v-on:click="prevPage()">
                    <v-icon class="size_arrow color_arrow">
                      mdi-chevron-left
                    </v-icon>
                  </v-btn>
                </v-card-actions>

                <v-card-actions
                  class="justify-center paging_round"
                  id="bt_s"
                  v-for="n in arrs"
                  :key="n"
                >
                  <v-card-actions v-if="total_page >= n">
                    <v-btn
                      v-if="now_page == n"
                      class="paging_num paging_on"
                      depressed
                      fab
                      small
                      dark
                      v-on:click="nowData(n)"
                    >
                      {{ n }}
                    </v-btn>
                    <v-btn
                      v-else
                      class="paging_num paging_off"
                      outlined
                      depressed
                      fab
                      small
                      dark
                      v-on:click="nowData(n)"
                    >
                      {{ n }}
                    </v-btn>
                  </v-card-actions>
                </v-card-actions>

                <v-card-actions
                  class="justify-center"
                  style="padding:0px !important;"
                >
                  <v-btn icon class="size_arrow" v-on:click="nextPage()">
                    <v-icon class="size_arrow color_arrow">
                      mdi-chevron-right
                    </v-icon>
                  </v-btn>
                </v-card-actions>
                <v-card-actions class="justify-center pa-0">
                  <v-btn icon class="size_arrow" v-on:click="last_nextPage()">
                    <v-icon class="size_arrow color_arrow">
                      mdi-chevron-double-right
                    </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card-actions>
            </v-row>
          </v-card>
          <v-btn
            class="rounded-0 rounded-b-xl button_3"
            v-on:click="$router.push('/counsel_write')"
          >
            {{ $ml.get("counsel_list_btn") }}
          </v-btn>
        </v-card>
      </v-row>
    </v-card>
  </v-main>
</template>
<script>
export default {
  created() {
    //api_counseling_list
    var self = this;
    var api = "api_counseling_list.php";
    var param = {
      page: 1
    };

    self.$store.dispatch("apiCall", { api, param }).then(() => {
      var data = self.$store.state.api_result;
      if (data == false || data == "err") {
        //error
        alert(
          "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
        );
      } else {
        try {
          if (data.ret_code == "S") {
            //리스트 없으면 빈 리스트 화면으로
            // if (data.total_page_count <= 0) {
            //   return;
            // }

            self.now_page = 1;
            self.total_page = data.total_page_count;
            if (data.total_page_count <= 5) {
              self.start_page = 1;
              self.end_page = data.total_page_count;
            } else {
              self.start_page = 1;
              self.end_page = 5;
            }
            if (data.comment_content != null) {
              if (data.comment_content.length >= 250) {
                self.reply_cont = data.comment_content.substring(0, 250);
                self.reply_cont = self.reply_cont + "...";
                self.reply_cont_to = data.comment_content;
              } else {
                self.reply_cont = data.comment_content;
                self.reply_cont_to = data.comment_content;
              }
            } else {
              self.reply_cont = "";
            }
            //self.reply_cont = data.comment_content;
            self.result = data;
          } else {
            alert(
              "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
            );
          }
        } catch (e) {
          alert(
            "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
          );
        }
      }
    });
  },
  mounted: function() {},
  data: function() {
    return {
      counsel_cont:
        "마음이 뻐근하고 목이 답답해요. 이런 증상이 있은지 3개월이 넘었어요. 너무 피곤한데 잠도 잘 안오고 어떻게 해야할지 모르겠어요. ",
      reply_cont:
        "안녕하세요. 마음 지킴이 코치 입니다. 마음에 불안 증상이 지속되다 보면 뻐근하고 답답한 신체적인 반응이 발생합니다. 코로나19로 인해 답답한 상황이 이어지고 있어 해소가더 쉽지 않은 현실입니다. 가급적 하루에 한번 이상 이천 더 건강 운동센터의 운동처방을 따라서 하시기를 권합니다. 마음이 답답할 때는 내가 믿을 수 있는 지인과의 가벼운 티타임도 도움이 될 수 있습니다. 사람을 만나는 것이 쉽지 않다면, 마음 운동을 통해 마음의 근육을 이완 시켜주세요. 더 많은 도움이 필요하다면 [메뉴 - 유관기관] 중에 가족지원센터에 연락하셔서 더 자세한 상담을 받아보시기를 추천합니다. 당신의 삶을 응원합니다.",
      reply_cont_to: "",
      title: "마음소통창구",
      counsel: "상담하기",
      number: "1",
      btNum_1: 1,
      btNum_2: 2,
      btNum_3: "3",
      btNum_4: "4",
      btNum_5: "5",
      input_date: "2020.08.20",
      output_date: "2020.08.24",
      more: "더보기",
      content_more: false,
      answer_more: false,
      arrs: [1, 2, 3, 4, 5],
      result: {},
      start_page2: 5,
      start_page: 0,
      end_page: 0,
      now_page: 0,
      pageSize: 1,
      total_page: 0,
      i: 0,
      currentPage: 1,
      order_kit: false
    };
  },
  methods: {
    nextPage: function() {
      this.num = this.now_page + 1;
      if (this.total_page >= this.num) {
        if (this.end_page <= this.num) {
          this.start_page = this.num;
          this.end_page = this.start_page + 4;
          if (this.end_page >= this.total_page) {
            this.end_page = this.total_page;
          }
          this.nowData(this.num);
        } else {
          this.nowData(this.num);
        }
      }

      //alert(this.start_page);
    },
    last_nextPage: function() {
      this.nowData(this.total_page);
    },
    prevPage: function() {
      this.num = this.now_page - 1;
      if (this.num >= 1) {
        this.start_page = this.num;
        this.nowData(this.num);
      }
    },
    first_prevPage: function() {
      this.nowData(1);
    },
    nowData: function(num) {
      //api_counseling_list
      var self = this;
      var api = "api_counseling_list.php";
      var param = {
        page: num
      };

      self.$store.dispatch("apiCall", { api, param }).then(() => {
        var data = self.$store.state.api_result;
        if (data == false || data == "err") {
          //error
        } else {
          try {
            if (data.ret_code == "S") {
              self.now_page = num;
              if (data.total_page_count <= 5) {
                self.start_page = 1;

                self.end_page = data.total_page_count;
              } else if (self.now_page <= 5) {
                self.arrs = [1, 2, 3, 4, 5];
              } else {
                var page_arr_sum =
                  self.now_page % 5 >= 1
                    ? parseInt(self.now_page / 5) * 5 + 5
                    : parseInt(self.now_page / 5) * 5;
                self.arrs = [
                  page_arr_sum - 4,
                  page_arr_sum - 3,
                  page_arr_sum - 2,
                  page_arr_sum - 1,
                  page_arr_sum
                ];
              }

              self.total_page = data.total_page_count;
              self.result = data;
            } else if (data.ret_code == "I") {
              //error
            } else {
              //error
            }
          } catch (e) {
            //error
          }
        }
      });
    },
    change_more: function() {
      var temp_content = this.$refs.ref_content.offsetHeight - 73;
      if (temp_content > 0) {
        this.$refs.ref_content_more.style.height = temp_content + 10 + "px";
        this.$refs.ref_content_more_btn.style.display = "block";
      } else {
        this.$refs.ref_content_more.style.height = 0;
        this.$refs.ref_content_more_btn.style.display = "none";
      }
      var temp_answer = this.$refs.ref_answer.offsetHeight - 150;
      if (temp_answer > 0) {
        this.$refs.ref_answer_more.style.height = temp_answer + 10 + "px";
        this.$refs.ref_answer_more_btn.style.display = "block";
      } else {
        this.$refs.ref_answer_more.style.height = 0;
        this.$refs.ref_answer_more_btn.style.display = "none";
      }
    }
  }
  // updated() {
  //   this.$nextTick(function () {
  //     this.change_more();
  //   });
  // }
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/common";

.bg_card {
  background-image: url("../assets/bg_counsel.jpg");
}

.table_title {
  font: normal normal bold 20px/35px Noto;
  color: $ft-color-counsel_list-table_title;
  line-height: 62px;
  height: 62px;
}

.table_title .left {
  width: 102px;
}

.table_title .right {
  width: 180px;
}

.table_text {
  background-color: $bg-color-counsel_list-table-text;
  font: normal normal normal 20px/35px Noto;
  color: $ft-color-counsel_list-table_text;
  white-space: pre-wrap;
}

.table_text .left {
  width: 102px;
  padding: 38px 0px 38px 0px;
}

.table_text .center {
  width: 0px;
  padding: 12px;
  padding: 38px 10px 38px 10px;
  text-align: left;
  line-height: 30px;
}

.table_text .right {
  width: 180px;
  padding: 38px 0px 38px 0px;
}

.bar_right_line {
  font: normal normal bold 20px/35px Noto;
  color: $ft-color-counsel_list-table-line-right;
  line-height: 58px !important;
}

.bar_bottom_line {
  background-color: $ft-color-counsel_list-table-line-bottom;
  background-position: top center;
  height: 1px;
}

.table_bg {
  background-color: $bg-color-counsel_list-table;
}

.paging_bg {
  width: 100%;
  padding: 15px 0px;
  background-color: $bg-color-counsel_list-table-paging;
}

.paging_round {
  min-width: 72px;
}

.paging_num {
  width: 48px;
  height: 48px;
  font: normal normal bold 20px/35px Noto;
}

.paging_on {
  background-color: $bg-color-counsel-list-table-paging-btn-on !important;
  color: $ft-color-counsel-list-table-paging-btn-on;
}

.paging_off {
  border: 4px solid $bg-color-counsel-list-table-paging-btn-off;
  color: $ft-color-counsel-list-table-paging-btn-off;
}

.size_arrow {
  width: 36px;
  height: 36px;
  font-size: 36px;
}

.color_arrow {
  color: $ft-color-counsel_list-table-paging;
}

/* mobile */
.sm.bg_card {
  background-image: url("../assets/bg_counsel_m.jpg");

  .table_title {
    font: normal normal bold 3.8vw/5vw Noto;
    line-height: 11vw;
    height: 11vw;
  }

  .table_title .left {
    display: none;
  }

  .table_title .right {
    display: none;
  }

  .table_text {
    background-color: $bg-color-counsel_list-table-text-m;
    font: normal normal normal 3.6vw/5vw Noto;
  }

  .table_text .left {
    display: none;
  }

  .table_text .right {
    display: none;
  }

  .bar_right_line {
    display: none;
  }

  .table_bg {
    background-color: $bg-color-counsel_list-table-m;
  }

  .paging_round {
    min-width: 11vw;
  }

  .paging_num {
    width: 8vw;
    height: 8vw;
    font: normal normal bold 3.6vw/5vw Noto;
  }

  .size_arrow {
    width: 8vw;
    height: 8vw;
    font-size: 8vw;
  }

  .paging_bg {
    padding: 0px;
    background-color: $bg-color-after_use_list-paging;
  }

  .paging_off {
    border: 5px solid $bg-color-counsel-list-table-paging-btn-off-border;
    color: $ft-color-counsel-list-table-paging-btn-off;
  }
}
</style>
