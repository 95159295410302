<template>
  <v-main class="fullwidth fullheight bg_card" :class="$mq">
    <!-- PC 웹 & 모바일 -->
    <v-card
      flat
      tile
      class="mx-auto"
      min-width="320"
      :max-width="$store.state.web_max_width"
      color="transparent"
    >
      <v-row justify="center" class="title_root">
        <v-col cols="12" class="title_root_text">
          {{ $ml.get("main_button_03") }}
        </v-col>
        <div class="title_root_text_line"></div>
        <v-btn
          elevation="0"
          class="title_root_back"
          icon
          v-on:click="$router.go(-1)"
        >
        </v-btn>
      </v-row>

      <v-row justify="center" class="main_root">
        <v-card
          :class="$mq == 'sm' ? 'rounded-xl' : 'rounded-b-xl'"
          color="transparent"
          width="100%"
        >
          <v-row justify="center" class="main_title_1">
            <v-btn
              elevation="0"
              class="main_root_back"
              icon
              v-on:click="$router.go(-1)"
            >
            </v-btn>
            <v-col cols="12" class="main_title_1_text">
              {{ title }}
            </v-col>
          </v-row>

          <v-card
            elevation="0"
            min-height="300"
            class="d-flex flex-column align-center justify-center rounded-0 main_title_1_text"
            :color="$mq == 'sm' ? '#FFFFFFBB' : '#00000066'"
          >
            <v-card
              v-if="$mq == 'sm'"
              elevation="0"
              min-height="30vw"
              width="70vw"
              color="transparent"
              class="d-flex align-center justify-center main_title_1_text"
            >
              {{ $ml.get("counsel_login_title") }}
            </v-card>
            <v-form ref="form" v-model="valid">
              <v-text-field
                class="login_input"
                :class="$mq == 'sm' ? 'rounded-lg' : 'rounded-xl'"
                solo
                :height="$mq == 'sm' ? '16vw' : 58"
                :placeholder="$ml.get('counsel_login_tel_label')"
                persistent-hint
                :rules="tel_rules"
                :value="input_tel"
                @change="v => (input_tel = v)"
                required
              ></v-text-field>

              <v-text-field
                class="login_input"
                :class="$mq == 'sm' ? 'rounded-lg' : 'rounded-xl'"
                solo
                :height="$mq == 'sm' ? '16vw' : 58"
                :append-icon="show_pw ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show_pw ? 'text' : 'password'"
                @click:append="show_pw = !show_pw"
                :placeholder="$ml.get('counsel_login_pw_label')"
                persistent-hint
                :rules="pw_rules"
                :value="input_pw"
                @change="v => (input_pw = v)"
                required
              ></v-text-field>
            </v-form>
            <div class="find_pw_text" @click="$router.push('/find_pw')">
              {{ $ml.get("counsel_login_find_pw") }}
            </div>
          </v-card>
          <v-btn class="rounded-0 rounded-b-xl button_3" v-on:click="login()">
            {{ $ml.get("counsel_login_btn") }}
          </v-btn>
        </v-card>
      </v-row>
    </v-card>
  </v-main>
</template>

<script>
import { regexCheck } from "../common";

export default {
  data: function() {
    return {
      valid: false,
      input_tel: "",
      show_pw: false,
      input_pw: "",
      tel_rules: [
        value => {
          return (
            regexCheck(1, value) || this.$ml.get("counsel_login_tel_rules_1")
          );
        },
        value =>
          (value || "").length == 11 ||
          this.$ml.get("counsel_login_tel_rules_2")
      ],
      pw_rules: [
        value => {
          return (
            regexCheck(1, value) || this.$ml.get("counsel_login_pw_rules_1")
          );
        },
        v => v.length == 4 || this.$ml.get("counsel_login_pw_rules_2")
      ]
    };
  },
  computed: {
    title() {
      return this.$mq == "sm"
        ? this.$ml.get("main_button_03")
        : this.$ml.get("counsel_login_title");
    }
  },
  methods: {
    login: function() {
      if (!this.$refs.form.validate()) {
        return;
      } else {
        //api_login
        var self = this;
        var api = "api_login.php";
        var param = {
          tel: encodeURI(this.input_tel),
          password: encodeURI(this.input_pw)
        };

        self.$store.dispatch("apiCall", { api, param }).then(() => {
          var data = self.$store.state.api_result;

          if (data == false || data == "err") {
            // error
            alert(
              "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
            );
          } else {
            if (data.ret_code == "S") {
              const p_cookie = data.login_token;
              self.$store.dispatch("login", { p_cookie });
              self.$router.push("/counsel_list");
            } else {
              const title = "";
              const info = self.$ml.get("counsel_login_alert_error_1");
              self.$store.commit("alert_show", { title, info });
            }
          }
        });
      }
    }
  },
  created() {},
  watch: {}
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/common";

.bg_card {
  background-image: url("../assets/bg_counsel.jpg");
}

.login_input {
  width: 400px;
  font: normal normal normal 18px/24px Noto !important;
  letter-spacing: 0px;
}

.find_pw_text {
  border-bottom: solid 2px $ft-color-counsel_login-find-pw;
  border-radius: 0px !important;
  align-self: center;
  font: normal normal normal 24px/30px Noto;
  letter-spacing: 0px;
  color: $ft-color-counsel_login-find-pw;
  cursor: pointer;
}

.sm.bg_card {
  background-image: url("../assets/bg_counsel_m.jpg");

  .login_input {
    width: 80vw;
    font: normal normal normal 4.6vw/6vw Noto !important;
  }

  .find_pw_text {
    border-bottom: solid 2px $ft-color-counsel_login-find-pw-m;
    font: normal normal normal 4.6vw/6vw Noto !important;
    color: $ft-color-counsel_login-find-pw-m;
    margin-bottom: 15vw;
  }
}
</style>
