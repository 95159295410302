<template>
  <v-col
    class="d-flex flex-column justify-center align-center bg_colorSet"
    :class="$mq"
  >
    <!-- <v-col> 감사가 일상이 되는 기적 "감사일기"
    </v-col> -->

    <v-card
      elevation="0"
      height="100"
      class="d-flex align-center justify-center rounded-0 info_text_1"
      color="transparent"
      v-html="title"
    >
    </v-card>

    <v-btn
      class="rounded-xl thx_send_button"
      :class="$mq == 'sm' ? 'mb-5' : 'mb-6'"
      content_tap_btn_bg
      v-on:click="$router.push('/applythanksdiary')"
    >
      {{ $ml.get("after_use_list_send_btn") }}
    </v-btn>

    <div class="thanks_diary_alert_info">
      {{ $ml.get("apply_thx_diary_alert_info") }}
    </div>

    <v-img src="@/assets/thank_diary_1.jpg"> </v-img>

    <iframe
      class="urlYoutube"
      content_tap_btn_bg
      src="https://www.youtube.com/embed/M-PAoXP64yo"
      title="YouTube video player"
      frameborder="0"
      allow="
      accelerometer; 
      autoplay; 
      clipboard-write; 
      encrypted-media; 
      gyroscope; 
      picture-in-picture
      "
      allowfullscreen
    >
    </iframe>

    <v-img src="@/assets/thank_diary_2.jpg"> </v-img>
  </v-col>
</template>

<script>
export default {
  props: ["title"]
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/common";
.bg_colorSet {
  background-color: $bg-color-contents-tanksdiary;
}

.urlYoutube {
  width: 850px;
  height: 500px;
  margin-bottom: 100px;
}

.thx_send_button {
  width: 50%;
  height: 60px !important;
  background-color: $m_button_text !important;
  font: normal normal bold 20px Noto !important;
  color: $m_button_text_white;
  border: 2px solid white;
}

.thanks_diary_alert_info {
  // margin-top: 10px;
  font: normal normal normal 17px Noto !important;
  color: $ft_thanks_diary_alert_info;
}

/* mobile */
.sm.bg_colorSet {
  .urlYoutube {
    width: 80vw;
    height: 50vw;
    margin-bottom: 5vw;
  }

  .thx_send_button {
    height: 10vw !important;
    font: normal normal bold 4vw Noto !important;
  }

  .thanks_diary_alert_info {
    font: normal normal normal 3vw Noto !important;
  }
}
</style>
