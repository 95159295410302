<template>
  <v-col class="listCSS pa-0" :class="$mq">
    <v-col class="listPosition" v-for="(item, index) in data" :key="index">
      <v-col style="font-weight: bold; font-size: 20px;" v-html="item.title">
      </v-col>

      <v-col justify="center" align="center">
        <v-img :src="item.Thumbnail_url" class="listIMG" contain />
      </v-col>
    </v-col>
  </v-col>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.listCSS {
  // max-height: 31rem;
  // max-height: 50vh;
  margin: 0;
  // height: auto;
}

.listCSS.sm {
  // height: auto;
}
</style>
