<template>
  <v-main class="fullwidth fullheight bg_card" :class="$mq">
    <!-- PC 웹 & 모바일 -->
    <v-card
      flat
      tile
      class="mx-auto"
      min-width="320"
      :max-width="$store.state.web_max_width"
      color="transparent"
    >
      <v-row justify="center" class="title_root">
        <v-col cols="12" class="title_root_text">
          {{ $ml.get("apply_thx_diary_title") }}
        </v-col>
        <div class="title_root_text_line"></div>
        <v-btn
          elevation="0"
          class="title_root_back"
          icon
          v-on:click="$router.go(-1)"
        >
        </v-btn>
      </v-row>

      <v-row justify="center" class="main_root">
        <v-card
          width="100%"
          :class="$mq == 'sm' ? 'rounded-xl' : 'rounded-b-xl'"
          class="mainCard"
        >
          <v-row justify="center" class="main_title_1">
            <v-btn
              v-if="$mq === 'sm'"
              elevation="0"
              class="main_root_back"
              icon
              v-on:click="$router.go(-1)"
            >
            </v-btn>

            <v-col 
              cols="12" 
              class="main_title_1_text"
              v-html="title"
            >
            </v-col>
            <v-col
              v-if="$mq === 'sm'"
              class="title_text_css"
              v-html="this.$ml.get('thanks_diary_title_info_sub_title')"
            >
            </v-col>

            <!-- <v-col cols="12" class="main_title_1_text title_text_css" v-html="title">
            </v-col> -->
            
          </v-row>

          <v-row justify="center" class="ma-0">
            <v-img src="@/assets/thank_banner.png"> </v-img>
          </v-row>

          <v-form ref="form" class="apply_thx_diary_input_root">
            <v-row class="ma-0 checkBoxPosition">
              <v-checkbox
                class="apply_thx_diary_input checkBox"
                v-model="checkbox"
                :rules="[v => !!v || $ml.get('apply_thx_diary_check_error_1')]"
                :label="$ml.get('apply_thx_diary_check_label')"
                required
              >
              </v-checkbox>
              <div class="open_url">
                <span class="url_position" @click="openURL()">
                  <b
                    ><u>{{
                      $ml.get("apply_thx_diary_check_label_click")
                    }}</u></b
                  >
                </span>
              </div>
            </v-row>
            <v-row class="ma-0">
              <v-col
                class="pa-0"
                :class="$mq == 'sm' ? '' : 'mr-5'"
                :cols="$mq == 'sm' ? '12' : ''"
              >
                <v-text-field
                  class="apply_thx_diary_input"
                  :class="$mq == 'sm' ? 'rounded-lg' : 'rounded-xl'"
                  solo
                  dense
                  outlined
                  :height="$mq == 'sm' ? '10vw' : 58"
                  :placeholder="$ml.get('apply_thx_diary_name_label')"
                  persistent-hint
                  :value="input_name"
                  :rules="name_rules"
                  @change="v => (input_name = v)"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                class="pa-0"
                :class="$mq == 'sm' ? '' : 'mr-5'"
                :cols="$mq == 'sm' ? '12' : ''"
              >
                <v-select
                  class="apply_thx_diary_input"
                  :class="$mq == 'sm' ? 'rounded-lg' : 'rounded-xl'"
                  solo
                  dense
                  outlined
                  :height="$mq == 'sm' ? '10vw' : 58"
                  :items="items"
                  :placeholder="$ml.get('apply_thx_diary_sex_label')"
                  :value="input_sex"
                  :rules="[v => !!v || $ml.get('apply_thx_diary_sex_error_1')]"
                  @change="v => (input_sex = v)"
                  required
                ></v-select>
              </v-col>
              <v-col class="pa-0" :cols="$mq == 'sm' ? '12' : ''">
                <v-text-field
                  class="apply_thx_diary_input"
                  :class="$mq == 'sm' ? 'rounded-lg' : 'rounded-xl'"
                  solo
                  dense
                  outlined
                  :height="$mq == 'sm' ? '10vw' : 58"
                  :placeholder="$ml.get('apply_thx_diary_birthday_label')"
                  persistent-hint
                  :value="input_birth"
                  :rules="birthday_rules"
                  @change="v => (input_birth = v)"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-text-field
              class="apply_thx_diary_input"
              :class="$mq == 'sm' ? 'rounded-lg' : 'rounded-xl'"
              solo
              dense
              outlined
              :height="$mq == 'sm' ? '10vw' : 58"
              :placeholder="$ml.get('counsel_login_tel_label')"
              persistent-hint
              :rules="tel_rules"
              :value="input_tel"
              @change="v => (input_tel = v)"
              required
            ></v-text-field>

            <v-text-field
              class="apply_thx_diary_input"
              :class="$mq == 'sm' ? 'rounded-lg' : 'rounded-xl'"
              solo
              dense
              outlined
              readonly
              :height="$mq == 'sm' ? '10vw' : 58"
              :value="input_addr_full"
              :placeholder="$ml.get('apply_thx_diary_addr_label')"
              :rules="address_rules"
              @click="show_addr()"
            ></v-text-field>
          </v-form>
          <v-btn
            class="rounded-0 rounded-b-xl button_1"
            v-on:click="applyThanksDiary()"
          >
            {{ $ml.get("apply_thx_diary_btn") }}
          </v-btn>
        </v-card>
      </v-row>
    </v-card>

    <!-- addr alert -->
    <v-dialog
      :content-class="$mq != 'sm' ? 'popup ' + $mq : 'dialog_full ' + $mq"
      v-model="alert_address"
      max-width="800"
      persistent
    >
      <v-card class="pa-5 popup_root">
        <v-btn
          elevation="0"
          class="add_img_del_btn"
          width="48"
          height="48"
          icon
          v-on:click="$router.go(-1)"
        >
        </v-btn>

        <v-card-title class="alert_title">
          <v-spacer />{{ $ml.get("apply_thx_diary_alert_title") }}<v-spacer />
        </v-card-title>

        <v-card-text class="pa-0 my-10 d-flex flex-column alert_title_sub_info">
          <div v-show="!show_daum_postcode" @click="execDaumPostcode()">
            <v-text-field
              style="width:150px;"
              class="apply_thx_diary_input"
              :class="$mq == 'sm' ? 'rounded-lg' : 'rounded-xl'"
              solo
              dense
              outlined
              :height="$mq == 'sm' ? '10vw' : 58"
              :placeholder="this.$ml.get('order_kit_addr_alert_input_1')"
              :value="input_address_zipcode"
              disabled
            ></v-text-field>

            <v-text-field
              style="width:400px;"
              class="apply_thx_diary_input"
              :class="$mq == 'sm' ? 'rounded-lg' : 'rounded-xl'"
              solo
              dense
              outlined
              :height="$mq == 'sm' ? '10vw' : 58"
              :placeholder="this.$ml.get('order_kit_addr_alert_input_2')"
              :value="input_address"
              disabled
            ></v-text-field>
          </div>

          <v-form ref="addr_form">
            <v-text-field
              v-show="!show_daum_postcode"
              class="apply_thx_diary_input"
              :class="$mq == 'sm' ? 'rounded-lg' : 'rounded-xl'"
              solo
              dense
              outlined
              :height="$mq == 'sm' ? '10vw' : 58"
              :placeholder="this.$ml.get('order_kit_addr_alert_input_3')"
              persistent-hint
              :value="input_address_etc"
              :rules="address_etc_rules"
              @change="v => (input_address_etc = v)"
            ></v-text-field>
          </v-form>

          <div v-show="show_daum_postcode" id="wrap" class="postcode"></div>
        </v-card-text>

        <v-btn class="mt-5 rounded-xl alert_btn" v-on:click="make_addr_full()">
          {{ $ml.get("alert_ok_btn") }}
        </v-btn>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import { regexCheck } from "../common/index.js";
export default {
  created() {},

  data: function() {
    return {
      items: [
        this.$ml.get("apply_thx_diary_sex_item_1"),
        this.$ml.get("apply_thx_diary_sex_item_2")
      ],
      link: "https://www.gjmind.or.kr/www/support/privacy.html",
      checkbox: false,
      input_name: "",
      input_birth: "",
      input_sex: "",
      input_tel: "",
      show_daum_postcode: true,
      input_address: "",
      input_address_etc: "",
      input_address_zipcode: "",
      alert: false,
      order_kit_finish: false,
      alert_address: false,
      input_addr_full: "",
      name_rules: [
        value => {
          return (
            value.trim().length > 0 ||
            this.$ml.get("after_use_write_alert_error_3")
          );
        },

        value => {
          return (
            regexCheck(2, value) ||
            this.$ml.get("after_use_write_alert_error_7")
          );
        }
      ],
      birthday_rules: [
        value => {
          return (
            regexCheck(1, value) || this.$ml.get("counsel_login_tel_rules_1")
          );
        },

        value => {
          return (
            value.trim().length == 6 || this.$ml.get("counsel_birthday_check")
          );
        }
      ],
      tel_rules: [
        value => {
          return (
            regexCheck(1, value) || this.$ml.get("counsel_login_tel_rules_1")
          );
        },
        value =>
          (value || "").length == 11 ||
          this.$ml.get("counsel_login_tel_rules_2")
      ],
      address_rules: [
        value =>
          (value || "").length != "" || this.$ml.get("thanksdiary_addr_rules")
      ],
      address_etc_rules: [
        value =>
          (value || "").length != "" ||
          this.$ml.get("thanksdiary_addr_etc_rules_1")
      ]
    };
  },
  computed: {
    title() {
      return this.$mq == "sm"
        ? this.$ml.get("apply_thx_diary_title") 
        //+  this.$ml.get("thanks_diary_title_info_sub_title")
        : this.$ml.get("thanks_diary_title_info_and_sub_title") 
    }
  },
  methods: {
    show_addr: function() {
      var target = this.$route.fullPath.substring(
        this.$route.fullPath.indexOf("#")
      );
      //#alert_address가
      // 있는 상태이면 팝업만 띄움
      if (target == "#alert_address") {
        this.alert_address = true;

        var self = this;
        setTimeout(function() {
          self.execDaumPostcode();
        }, 300);
      }
      //아니면 push
      else {
        this.$router.push({ hash: "#alert_address" });
      }
    },

    applyThanksDiary() {
      if (this.$refs.form != null && !this.$refs.form.validate()) {
        return;
      } else {
        //api_kit_add
        var self = this;
        var api = "api_kit_add.php";
        var param = {
          name: encodeURI(this.input_name),
          sex: encodeURI(this.input_sex),
          tel: encodeURI(this.input_tel),
          birthday: encodeURI(this.input_birth),
          address: encodeURI(this.input_addr_full)
          // gift_address: encodeURI(this.input_address),
          // gift_address_etc: encodeURI(this.input_address_etc),
          // gift_address_zipcode: encodeURI(this.input_address_zipcode)
        };

        self.$store.dispatch("apiCall", { api, param }).then(() => {
          var data = self.$store.state.api_result;
          // console.log(data);
          if (data == false || data == "err") {
            //error
            alert(
              "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
            );
          } else {
            try {
              if (data.ret_code == "S") {
                // self.alert_title = "키트 신청 완료";
                const title = this.$ml.get("after_send_alert_title");
                const info = this.$ml.get("after_send_btn_click");
                const fn = function() {
                  self.$router.replace("/contents");
                  // self.$router.go(-1);
                };

                self.$store.commit("alert_show", { title, info, fn });
                // self.order_kit_finish = true;
                // self.$router.push({ hash: "#alert" });
              } else if (data.ret_code == "I") {
                const title = this.$ml.get("after_send_alert_fail_title");
                const info = data.ret_msg;
                this.$store.commit("alert_show", { title, info });
                return;
              } else if (data.ret_code == "FS") {
                const title = this.$ml.get("after_send_alert_fail_title");
                const info = data.ret_msg;
                this.$store.commit("alert_show", { title, info });
                return;
              }
            } catch (e) {
              alert(
                "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
              );
            }
          }
        });
      }
    },

    execDaumPostcode() {
      var self = this;
      var element_wrap = document.getElementById("wrap");
      self.show_daum_postcode = true;

      // eslint-disable-next-line no-undef
      new daum.Postcode({
        oncomplete: function(data) {
          // console.log(data);
          // 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

          // 각 주소의 노출 규칙에 따라 주소를 조합한다.
          // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
          var addr = ""; // 주소 변수

          //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
          if (data.userSelectedType === "R") {
            // 사용자가 도로명 주소를 선택했을 경우
            addr = data.roadAddress;
          } else {
            // 사용자가 지번 주소를 선택했을 경우(J)
            addr = data.jibunAddress;
          }

          // 우편번호와 주소 정보를 해당 필드에 넣는다.
          self.input_address_zipcode = data.zonecode;
          self.input_address = addr;

          //주소 보여주기
          self.show_daum_postcode = false;
        },
        // 우편번호 찾기 화면 크기가 조정되었을때 실행할 코드를 작성하는 부분. iframe을 넣은 element의 높이값을 조정한다.
        onresize: function() {
          // element_wrap.style.height = size.height+'px';
        },
        width: "100%",
        height: "100%",
        submitMode: false
      }).embed(element_wrap, { autoClose: true });
    },

    make_addr_full: function() {
      if (!this.$refs.addr_form.validate()) {
        return;
      } else {
        var self = this;
        self.input_addr_full = `(${self.input_address_zipcode}) ${self.input_address} ${self.input_address_etc}`;
        self.$router.go(-1);
      }
    },

    openURL() {
      window.open(
        this.link,
        "새창",
        "width=1500, height=1000, toolbar=no, menubar=no, scrollbars=no, resizable=yes"
      );
    },

    openURL_m() {
      // window.open(this.link, "네이버새창", "width=100%, height=100%, toolbar=no, menubar=no, scrollbars=no, resizable=yes" );
    }
  },
  watch: {
    $route(to) {
      //팝업 띄움
      var target = to.fullPath.substring(to.fullPath.indexOf("#"));

      if (target === "#alert_address") {
        this.alert_address = true;

        var self = this;
        setTimeout(function() {
          self.execDaumPostcode();
        }, 300);
      } else if (target.indexOf("#") < 0) {
        this.alert_address = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/common";

.title_text_css {
  // align-self: center;
  font: normal normal normal 24px/30px Noto;
  letter-spacing: 0px;
  color: $ft-color-title;
  margin-top: -8vw;
}

.bg_card {
  background-image: url("../assets/bg_healing.jpg");
}

.mainCard {
  background-color: $bg-color-contents-tanksdiary;
}

.apply_thx_diary_input_root {
  padding: 0px 70px;
}

.apply_thx_diary_input {
  font: normal normal normal 18px/24px Noto !important;
  letter-spacing: 0px;
}

.checkBoxPosition {
  display: flex;
  flex-direction: row;
}

.open_url {
  display: flex;
  align-items: center;
  margin-top: 20px;
  height: 100%;
  min-height: 24px;
  // margin-left: 5px;
  // width: 100px !important;
}

.url_position {
  display: inline-block;
  width: 100% !important;
  // width: 76px !important;
  cursor: pointer;
}

/* alert */
.add_img_del_btn {
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 20px;
  background-image: url("../assets/image_cancle_button_x.png");
  background-size: contain;
}

.postcode {
  border: 1px solid;
  width: 100%;
  height: calc(90vh - 450px);
  min-height: 200px;
  margin: 0px;
  position: relative;
}

/* mobile */
.sm.bg_card {
  background-image: url("../assets/bg_healing_m.jpg");

  .apply_thx_diary_input_root {
    padding: 0px 3vw;
  }

  .apply_thx_diary_input {
    font: normal normal normal 3.2vw/5vw Noto !important;
  }

  .open_url {
    font: normal normal normal 3.2vw/5vw Noto !important;
  }

  .url_position {
    width: 20vw !important;
  }

  .title_text_css {
    font: normal normal bold 2.6vw/4vw Noto;
  }

  .main_title_1_text {
    padding-bottom: 1vw;
    letter-spacing: 0px;
    color: $ft-color-title;
  }
}

.sm {
  .postcode {
    height: calc(78vh - 240px);
  }
}
</style>
