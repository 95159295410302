<template>
  <v-main class="fullwidth fullheight bg_card" :class="$mq">
    <!-- PC 웹 & 모바일 -->
    <v-card
      flat
      tile
      class="mx-auto"
      min-width="320"
      :max-width="$store.state.web_max_width"
      color="transparent"
    >
      <v-row justify="center" class="title_root">
        <v-col cols="12" class="title_root_text">
          {{ $ml.get("main_button_01") }}
        </v-col>
        <div class="title_root_text_line"></div>
        <v-btn
          elevation="0"
          class="title_root_back"
          icon
          v-on:click="$router.go(-1)"
        >
        </v-btn>
      </v-row>

      <v-row justify="center" class="main_root">
        <v-card
          :class="$mq == 'sm' ? 'rounded-xl' : 'rounded-b-xl'"
          color="transparent"
          width="100%"
        >
          <v-row justify="center" class="main_title_1">
            <v-btn
              elevation="0"
              class="main_root_back"
              icon
              v-on:click="$router.go(-1)"
            >
            </v-btn>
            <v-col cols="12" class="main_title_1_text">
              {{ title }}
            </v-col>
          </v-row>
          <v-row v-if="$mq == 'sm'" justify="center" class="main_title_1 sub">
            <v-col cols="12" class="main_title_1_text">
              {{ $ml.get("info_title") }}
            </v-col>
          </v-row>

          <!-- <v-card
            elevation="0"
            min-height="250"
            class="d-flex align-center justify-center rounded-0 content"
            :color="$mq == 'sm' ? '#FFFFFFBB' : '#FFFFFF'"
            v-html="content"
          >
          </v-card>

          <v-card
            elevation="0"
            class="d-flex justify-center rounded-0 info_text_1"
            :class="$mq == 'sm' ? 'rounded-b-xl' : ''"
            :color="$mq == 'sm' ? '#FFFFFFBB' : '#FFFFFF'"
          >
            <v-img
              src="@/assets/info_logo.png"
              :height="$mq == 'sm' ? '15vw' : 50"
              contain
            >
            </v-img>
          </v-card> -->

          <v-card
            elevation="0"
            class="d-flex align-center justify-center rounded-0 rounded-b-xl pa-0 content"
            :color="$mq == 'sm' ? '#FFFFFFBB' : '#FFFFFF'"
          >
            <v-img
              :src="info_img"
              contain
            >
            </v-img>
          </v-card>
        </v-card>
      </v-row>
    </v-card>
  </v-main>
</template>

<script>
export default {
  data: function() {
    return {};
  },
  computed: {
    title() {
      return this.$mq == "sm"
        ? this.$ml.get("main_button_01")
        : this.$ml.get("info_title");
    },
    content() {
      return this.$mq == "sm"
        ? this.$ml.get("info_content_m")
        : this.$ml.get("info_content");
    },
    info_img() {
      return this.$mq == "sm"
        ? require("@/assets/info_m.jpg")
        : require("@/assets/info.jpg");
    }
  },
  methods: {},
  created() {},
  watch: {}
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/common";

.bg_card {
  background-image: url("../assets/bg_info.jpg");
}

.content {
  text-align: left;
  font: normal normal normal 18px/30px Noto;
  letter-spacing: 0px;
  color: $ft-color-title;
  padding: 40px;
}

/* mobile */
.sm.bg_card {
  background-image: url("../assets/bg_info_m.jpg");

  .content {
    font: normal normal normal 4vw/6vw Noto;
  }
}
</style>
