<template>
  <v-main class="fullwidth bg_card" :class="$mq">
    <!-- PC 웹 & 모바일 -->

    <InitDialog />

    <div class="pa-0 ma-0 fullwidth fullheight">
      <v-card
        flat
        tile
        class="mx-auto d-flex align-end"
        :width="1000"
        height="calc(100vh - 100px)"
        :min-height="$mq == 'sm' ? 568 : 700"
        color="transparent"
      >
        <v-row justify="center" class="animation_top main_button_root">
          <v-row no-gutters justify="center">
            <v-col :cols="$mq == 'sm' ? 6 : 0" v-on:click="goto('info')">
              <v-hover v-slot="{ hover }" class="main_button">
                <v-card
                  :elevation="hover ? 12 : 2"
                  class="rounded-xl button_bg bg_1"
                  :class="{ 'on-hover_1': hover }"
                >
                </v-card>
              </v-hover>
            </v-col>

            <v-col :cols="$mq == 'sm' ? 6 : 0" v-on:click="goto('d_start')">
              <v-hover v-slot="{ hover }" class="main_button">
                <v-card
                  :elevation="hover ? 12 : 2"
                  class="rounded-xl button_bg bg_2"
                  :class="{ 'on-hover_2': hover }"
                >
                </v-card>
              </v-hover>
            </v-col>

            <v-col
              :cols="$mq == 'sm' ? 6 : 0"
              v-on:click="goto('counsel_start')"
            >
              <v-hover v-slot="{ hover }" class="main_button">
                <v-card
                  :elevation="hover ? 12 : 2"
                  class="rounded-xl button_bg bg_3"
                  :class="{ 'on-hover_3': hover }"
                >
                  <v-chip
                    class="main_btn_count rounded-xl justify-center"
                    v-if="$store.state.counseling_read == 'Y'"
                  >
                    1
                  </v-chip>
                </v-card>
              </v-hover>
            </v-col>

            <v-col :cols="$mq == 'sm' ? 6 : 0" v-on:click="goto('contents')">
              <v-hover v-slot="{ hover }" class="main_button">
                <v-card
                  :elevation="hover ? 12 : 2"
                  class="rounded-xl button_bg bg_4"
                  :class="{ 'on-hover_4': hover }"
                >
                </v-card>
              </v-hover>
            </v-col>

            <v-col v-if="$mq != 'sm'" v-on:click="goto('after_use_list')">
              <v-hover v-slot="{ hover }" class="main_button">
                <v-card
                  :elevation="hover ? 12 : 2"
                  class="rounded-xl button_bg bg_5"
                  :class="{ 'on-hover_5': hover }"
                >
                </v-card>
              </v-hover>
            </v-col>

            <v-col v-if="$mq != 'sm'" v-on:click="go_home_page()">
              <v-hover v-slot="{ hover }" class="main_button">
                <v-card
                  :elevation="hover ? 12 : 2"
                  class="rounded-xl button_bg bg_6"
                  :class="{ 'on-hover_6': hover }"
                >
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-row>
        <!-- <home-result /> -->
      </v-card>
      <!-- footer -->
      <div
        class="d-flex align-center justify-center footer_root"
        v-html="footer"
      ></div>
    </div>
  </v-main>
</template>

<script>
// import HomeResult from "../components/HomeResult.vue";
import InitDialog from "../components/InitDialog.vue";

export default {
  components: {
    InitDialog
  },
  created() {
    //최상단으로 이동
    if (document.getElementById("inspire") != null) {
      document.getElementById("inspire").scrollTop = 0;
    }

    //통계
    //api_login_gubun
    var api = "api_login_gubun.php";
    var param = {};
    this.$store.dispatch("apiCall", { api, param }).then();
  },
  name: "Home",
  methods: {
    goto: function(type) {
      this.$router.push(type);
    },
    go_home_page: function() {
      window.open("https://www.gjmind.or.kr/", "_blank");
    },
    yourCallBackFunction() {
      var self = this;

      const timePeriodToExit = 2000;

      if (new Date().getTime() - this.lastTimeBackPress < timePeriodToExit) {
        navigator.app.exitApp();
      } else {
        self.$store.state.open_toast = true;
        setTimeout(function() {
          self.$store.state.open_toast = false;
        }, 2000);
        this.lastTimeBackPress = new Date().getTime();
      }
    }
  },
  mounted: function() {
    // 한번 동작한 뒤에는 animationend를 타지 않아서 setTimeout으로 변경
    // document.querySelector(".bg_card").addEventListener("animationend", function(){
    setTimeout(function() {
      setTimeout(function() {
        document.querySelector(".bg_card").classList.add("step2");

        var temp = document.getElementsByClassName("animation_big");
        for (var i = 0; i < temp.length; i++) {
          temp[i].classList.add("start");
        }
        var temp2 = document.getElementsByClassName("animation_top");
        for (var j = 0; j < temp2.length; j++) {
          temp2[j].classList.add("start");
        }
        var temp3 = document.getElementsByClassName("animation_bottom");
        for (var k = 0; k < temp3.length; k++) {
          temp3[k].classList.add("start");
        }
        var temp4 = document.getElementsByClassName("animation_spin");
        for (var x = 0; x < temp4.length; x++) {
          temp4[x].classList.add("start");
          temp4[x].style.opacity = 1;
        }
      }, 0);
    }, 0);
    // }, false);

    //android backbutton 종료
    document.addEventListener("backbutton", this.yourCallBackFunction, false);
  },
  data: function() {
    return {
      open_toast: true,
      lastTimeBackPress: 0
    };
  },
  computed: {
    footer() {
      return this.$mq == "sm"
        ? this.$ml.get("footer_m")
        : this.$ml.get("footer");
    }
  },
  initializeApp() {
    //android backbutton 종료
    // History Back 안되게...
    this.$store.actions.disableHistoryBack();
  },
  beforeDestroy() {
    //android backbutton 종료
    document.removeEventListener("backbutton", this.yourCallBackFunction);
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/common";
.bg_card {
  background-image: url("../assets/bg_main.jpg");
  background-attachment: initial;
  padding-top: 0px !important;
}

.main_button_root {
  margin-bottom: 64px;
}

.title_sub {
  font-size: 20px;
  font-family: "Noto";
  color: rgb(255, 255, 255);
  margin-top: 55px;
}

.button_bg {
  background-color: transparent;
  background-size: cover;
  width: 150px;
  height: 171px;
  margin: auto;
  cursor: pointer;
}

.button_bg.bg_1 {
  background-image: url("../assets/com_button_x_1.png") !important;
}

.button_bg.bg_2 {
  background-image: url("../assets/com_button_x_2.png") !important;
}

.button_bg.bg_3 {
  background-image: url("../assets/com_button_x_3.png") !important;
}

.button_bg.bg_4 {
  background-image: url("../assets/com_button_x_4.png") !important;
}

.button_bg.bg_5 {
  background-image: url("../assets/com_button_x_5.png") !important;
}

.button_bg.bg_6 {
  background-image: url("../assets/com_button_x_6.png") !important;
}

.button_bg.on-hover_1 {
  background-image: url("../assets/com_button_x_1_o.png") !important;
}

.button_bg.on-hover_2 {
  background-image: url("../assets/com_button_x_2_o.png") !important;
}

.button_bg.on-hover_3 {
  background-image: url("../assets/com_button_x_3_o.png") !important;
}

.button_bg.on-hover_4 {
  background-image: url("../assets/com_button_x_4_o.png") !important;
}

.button_bg.on-hover_5 {
  background-image: url("../assets/com_button_x_5_o.png") !important;
}

.button_bg.on-hover_6 {
  background-image: url("../assets/com_button_x_6_o.png") !important;
}

.main_btn_count {
  position: absolute;
  z-index: 100;
  font-family: "Noto";
  font-size: 24px !important;
  color: $bg-color-main-count;
  top: -18px;
  right: 0px;
  width: 46px;
  height: 46px;
}

/*footer css*/
.footer_root {
  height: 100px;
  font-family: Noto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: $ft-color-footer !important;
  background-color: $bg-color-footer !important;
}

/* mobile */
.sm.bg_card {
  background-image: url("../assets/bg_main_m.jpg");

  .main_button_root {
    margin: 0px 0px 4vw 0px;
    padding: 3vw;
  }

  .main_button_root .col {
    display: flex;
    justify-content: center;
  }

  .main_button_root .col:nth-child(-n + 2) {
    margin-bottom: 6vw;
  }

  .button_bg {
    width: 40vw;
    height: 28vw;
    cursor: pointer;
  }

  .button_bg.bg_1 {
    background-image: url("../assets/com_button_m_1.png") !important;
  }

  .button_bg.bg_2 {
    background-image: url("../assets/com_button_m_2.png") !important;
  }

  .button_bg.bg_3 {
    background-image: url("../assets/com_button_m_4.png") !important;
  }

  .button_bg.bg_4 {
    background-image: url("../assets/com_button_m_3.png") !important;
  }

  .button_bg.on-hover_1 {
    background-image: url("../assets/com_button_m_1_o.png") !important;
  }

  .button_bg.on-hover_2 {
    background-image: url("../assets/com_button_m_2_o.png") !important;
  }

  .button_bg.on-hover_3 {
    background-image: url("../assets/com_button_m_4_o.png") !important;
  }

  .button_bg.on-hover_4 {
    background-image: url("../assets/com_button_m_3_o.png") !important;
  }

  .footer_root {
    position: absolute;
    width: 100%;
    height: calc(17vw + 100px);
    padding-bottom: 100px;
    font-size: 2.4vw;
    text-align: center;
  }
}
</style>
