<template>
  <v-main class="fullwidth fullheight bg_card" :class="$mq">
    <!-- PC 웹 & 모바일 -->
    <v-card flat tile class="mx-auto" min-width="320" :max-width="$store.state.web_max_width" color="transparent">
      <v-row justify="center" class="title_root">
        <v-col cols="12" class="title_root_text font_white">
          {{ $ml.get("main_button_02") }}
        </v-col>
        <div class="title_root_text_line font_white"></div>
        <v-btn elevation="0" class="title_root_back" icon v-on:click="$router.go(-1)"> </v-btn>
      </v-row>

      <v-row justify="center" class="main_root">
        <v-card :class="$mq == 'sm' ? 'rounded-xl' : 'rounded-b-xl'" color="transparent" width="100%">
          <v-row justify="center" class="main_title_1">
            <v-btn elevation="0" class="main_root_back" icon v-on:click="$router.go(-1)"> </v-btn>
            <v-col cols="12" class="main_title_1_text">
              {{ title }}
            </v-col>
          </v-row>

          <v-card
            elevation="0"
            min-height="160"
            class="d-flex align-center justify-center rounded-0 ma-auto info_text_0 small"
            v-html="detail"
          >
          </v-card>
          <v-card
            v-for="(item, index) in list"
            :key="index"
            elevation="0"
            class="d-flex flex-column align-center rounded-0 pa-5 text_list_bg"
          >
            <v-container class="pa-0 d-flex fill-width">
              <div class="text_list_text text_list_num">
                {{ index + ". " }}
              </div>
              <div class="text_list_text text_list_mid" v-html="item.question_title"></div>
            </v-container>

            <!-- 텍스트 입력 형태 -->
            <v-container v-if="item.exemple_type === 'text'" class="d-flex flex-wrap fill-width text_list_btn_list">
              <v-form
                ref="form"
                v-for="(item_text, index_text) in item.exemple_list"
                :key="index_text"
                class="text_list_input"
                :class="['list_' + index, index + '_' + index_text]"
              >
                <v-text-field
                  class="counsel_write_input"
                  :class="$mq == 'sm' ? 'rounded-lg' : 'rounded-xl'"
                  solo
                  dense
                  :height="$mq == 'sm' ? '10vw' : 58"
                  :placeholder="item_text.exemple_text"
                  :rules="item.exemple_btn ? tel_rules : []"
                  @keyup="select(index, index_text, $event.target.value, item_text.exemple_point)"
                  required
                ></v-text-field>
              </v-form>
            </v-container>

            <!-- 체크박스 형태 -->
            <v-container v-else-if="item.exemple_type === 'checkbox'" class="d-flex flex-wrap fill-width text_list_btn_list">
              <v-checkbox
                label="개인정보 수집 및 이용에 대한 동의합니다."
                v-for="(item_2, index_2) in item.exemple_list"
                :value="item_2.exemple_text"
                v-model="agreePrivte"
                :key="index_2"
                class="agree_privte"
                :class="[index > 2 ? 'big' : '', 'list_' + index, index + '_' + index_2]"
                @change="select(index, index_2, agreePrivte, item_2.exemple_point.split('<')[0])"
              >
                {{ item_2.exemple_text }}
              </v-checkbox>
            </v-container>

            <!-- 버튼 선택 형태 -->
            <v-container v-else class="d-flex flex-wrap fill-width text_list_btn_list">
              <v-btn
                v-for="(item_2, index_2) in item.exemple_list"
                :key="index_2"
                class="rounded-0 text_list_text text_list_btn"
                :class="[index > 2 ? 'big' : '', 'list_' + index, index + '_' + index_2]"
                @click="select(index, index_2, item_2.exemple_text, item_2.exemple_point)"
              >
                {{ item_2.exemple_text }}
              </v-btn>
            </v-container>
            <div class="text_list_border_bottom"></div>
          </v-card>

          <v-btn class="rounded-0 rounded-b-xl button_1" v-on:click="finish()">
            {{ $ml.get("d_s_text_insert_btn") }}
          </v-btn>
        </v-card>
      </v-row>
    </v-card>
  </v-main>
</template>

<script>
import { regexCheck } from "../common";

export default {
  data: function() {
    return {
      title: "",
      detail: "",
      list: [],
      result: [],
      tel_rules: [
        (value) => {
          return regexCheck(1, value) || this.$ml.get("counsel_login_tel_rules_1");
        },
        (value) => (value || "").length == 11 || this.$ml.get("counsel_login_tel_rules_2"),
      ],
      agreePrivte: false,
    };
  },
  computed: {},
  methods: {
    select: function(idx, idx_2, text, point) {
      var temp_list = document.getElementsByClassName("list_" + idx);
      for (var i = 0; i < temp_list.length; i++) {
        temp_list[i].classList.remove("on");
      }

      var temp = document.getElementsByClassName(idx + "_" + idx_2);
      temp[0].classList.add("on");

      this.result[idx] = { text: text, point: point };
    },
    finish: function() {
      const title = this.$ml.get("main_button_02");

      for (let i = 0; i < this.result.length; i++) {
        if (!this.$refs.form[1].validate()) { // 전화번호 검사
          const info = this.$ml.get("d_s_text_not_phone");
          return this.$store.commit("alert_show", { title, info });
        } else if (this.result[i] == "" || this.result[i]?.text === false) { 
          const info = this.$ml.get("d_s_text_not_seleft");
          return this.$store.commit("alert_show", { title, info });
        }
      }

      //param 만들기
      let param = {};
      let num = 1;
      param.idx = this.$store.state.diagnosis_data.idx;
      this.result.forEach((item) => {
        if (item.point.indexOf("개인정보 동의") > -1) {
          param.agree = encodeURI(item.text);
        } else if (item.point == "이름") {
          param.name = encodeURI(item.text);
        } else if (item.point == "연락처") {
          param.phone = encodeURI(item.text);
        } else if (item.point == "성별") {
          param.sex = encodeURI(item.text);
        } else if (item.point == "연령") {
          param.age = encodeURI(item.text);
        } else if (item.point == "독거여부") {
          param.solitude = encodeURI(item.text);
        } else if (item.point == "거주지") {
          param.residence = encodeURI(item.text);
        } else {
          param["q" + num] = encodeURI(item.text);
          param["q" + num + "_point"] = item.point;
          num++;
        }
      });
      // alert(JSON.stringify(param));

      //api_counseling_insert
      var self = this;
      var api = "api_survey_question_answer_insert.php";

      self.$store.dispatch("apiCall", { api, param }).then(() => {
        var data = self.$store.state.api_result;

        if (data == false || data == "err") {
          // error
          alert("정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요.");
        } else {
          if (data.ret_code == "S") {
            self.$store.state.diagnosis_data_result = data;
            self.$router.push("/d_result");
          } else {
            alert(data.ret_msg);
          }
        }
      });
    },
    back: function() {
      if (this.step <= 0) {
        this.$router.go(-1);
      } else {
        this.step--;
        this.result.pop();
      }
    },
  },
  async created() {
    this.title = this.$store.state.diagnosis_data.title;
    //api_content_list
    var self = this;
    var api = "api_survey_question_list.php";
    var param = {
      idx: this.$store.state.diagnosis_data.idx,
    };

    await self.$store.dispatch("apiCall", { api, param }).then(() => {
      var data = self.$store.state.api_result;

      if (data == false || data == "err") {
        // error
        alert("정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요.");
      } else {
        if (data.ret_code == "S") {
          self.detail = data.detail;
          self.list = [];

          let temp = data.user_info;
          temp.forEach((item) => {
            let rename_list = [];
            item.exemple.forEach((text) =>
              rename_list.push({
                exemple_text: text.text,
                exemple_point: item.title,
              })
            );
            self.list.push({
              exemple_list: rename_list,
              idx: "0",
              question_title: item.title,
              questiont_order: "0",
              exemple_type: item.exemple_type,
              exemple_btn: item.title === "연락처" ? true : false,
            });
          });
          data.list.forEach((item) => {
            self.list.push(item);
          });

          self.list.forEach(() => {
            self.result.push("");
          });
        } else {
          alert("정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요.");
        }
      }
    });
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/common";

.bg_card {
  background-image: url("../assets/bg_self.jpg");
}

.info_text_0.small {
  font: normal normal bold 23px/28px Noto;
}

.text_list_bg {
  background-color: #f6f6f6;
}

.text_list_text {
  font: normal normal normal 21px/26px Noto !important;
  letter-spacing: 0px;
  color: $ft-color-title;
}

.text_list_num {
  width: 40px;
}

.text_list_mid {
  width: 100%;
  padding: 0px 40px 14px 0px;
  text-align: left;
}

.text_list_btn_list {
  padding: 0px 36px;
}

.text_list_btn {
  width: auto;
  min-width: 85px !important;
  height: 35px !important;
  margin: 4px;
  padding: 0px 10px !important;
  font: normal normal normal 17px/35px Noto !important;
  border: solid 1px #787878;
}

.text_list_input,
.text_list_btn.big {
  min-width: 400px !important;
}

.text_list_btn.on {
  background-color: #383f47 !important;
  color: #ffffff;
}

.text_list_border_bottom {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  border-bottom: solid 1px #e3e3e3;
}

.sm.bg_card {
  background-image: url("../assets/bg_self_m.jpg");
  .main_title_1_text.small {
    font: normal normal normal 5vw/6vw Noto;
  }

  .info_text_0.small {
    min-height: 0px !important;
    padding: 4vw !important;
    font: normal normal bold 4.5vw/5vw Noto;
  }

  .text_list_bg {
    padding: 2vw !important;
  }

  .text_list_text {
    font: normal normal normal 4vw/5vw Noto !important;
  }

  .text_list_num {
    width: 7vw;
  }

  .text_list_mid {
    padding: 0px 6vw 2vw 0px;
  }

  .text_list_btn_list {
    padding: 0px 0px 0px 5vw;
  }

  .text_list_input {
    min-width: 73vw !important;
    height: 17vw !important;
  }

  .text_list_btn {
    min-width: 73vw !important;
    height: 8vw !important;
    margin: 1vw;
    padding: 0px 2vw !important;
    font: normal normal normal 3.6vw/8vw Noto !important;
  }
}
</style>
